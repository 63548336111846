import { sha256 } from "js-sha256";

/*
    Custom implementation becouses at this time contentful + getsby does not support only password add to specific page
    You can add identity login for specific pages
    Or you can add one password for all the pages
    none of these approches are valid in this case, we need a password for all the sites seperately
*/

const siteDataKey = "gatsbySiteLoginData";
const userDataKey = "gatsbyUserData";

export const isBrowser = () => typeof window !== "undefined";

export const getLoginData = () =>
  isBrowser() && window.localStorage.getItem(userDataKey)
    ? JSON.parse(window.localStorage.getItem(userDataKey))
    : {};

export const getLoginDataForSite = () =>
  isBrowser() && window.localStorage.getItem(siteDataKey)
    ? JSON.parse(window.localStorage.getItem(siteDataKey))
    : {};

const setUser = (user) =>
  isBrowser() && window.localStorage.setItem(userDataKey, JSON.stringify(user));

const setSiteData = (siteData) =>
  isBrowser() &&
  window.localStorage.setItem(siteDataKey, JSON.stringify(siteData));

/*
    Main login function
    it takes the site id that the user is trying to login to
    and the password provided by the user
    takes the sideid and password hashes it with sha256
    checks if the hash matches what is expected
    if they match the login data is added to user local storage
    the login data is the hash that was provided hashed again with site id
*/
export const handleLogin = (siteId, password) => {
  const hash = sha256.create();
  hash.update(`${siteId};${password}`);

  const passhash = hash.hex();
  const siteData = getLoginDataForSite();

  if (passhash === siteData.hash) {
    window.localStorage.removeItem(siteDataKey);

    const secondHash = sha256.create();
    secondHash.update(`${siteId};${passhash}`);

    setUser({
      sitePass: secondHash.hex(),
    });

    return true;
  }

  return false;
};

/*
    Checks if the login data is present in the user local storage and if so
    is it the correct site's login data

    the siteId and password are provided by the site
    they are hashed and then the hash is added to the site id again and hashed again

    if what's present in the users local storage is the same as the expected hash
    the user is considered logged in
*/
export const isLoggedIn = (siteId, password) => {
  const hash = sha256.create();
  hash.update(`${siteId};${password}`);

  const secondhash = sha256.create();
  secondhash.update(`${siteId};${hash.hex()}`);

  const userData = getLoginData();

  return userData && userData.sitePass === secondhash.hex();
};

/*
    Prepares the site for login, this just creates the hash that's expected for login
    the hash consists of site id and password hashed with sha256

    everything is stored in the users local storage

    the slug is used for redirect after successfull login
*/
export const prepareLoginForSite = (props) => {
  const { siteId, password } = props;

  const hash = sha256.create();
  hash.update(`${siteId};${password}`);

  const siteData = {
    hash: hash.hex(),
    ...props,
  };

  setSiteData(siteData);
};
